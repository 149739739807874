require('./bootstrap');
require('datatables.net-bs4');
require('datatables.net-rowreorder-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-staterestore-bs4');
require('datatables.net-searchpanes-bs4');
require('datatables.net-select-bs4');
require('bootstrap-daterangepicker');
global.moment = require('moment');
require('tempusdominus-bootstrap-4');
require('select2/dist/js/select2.full.min');
require('bootstrap-touchspin');
require('js-cookie');
require('./scripts');
