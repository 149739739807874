(function($) {
    let companyChannel = null;

    $.fn.getCompanyChannel = function () {
        return companyChannel;
    }
    $.fn.dataTable.ext.errMode = 'none';
    $.fn.dataTable.ext.errCallback = function (e, settings) {
        if (settings.jqXHR.status === 401) {
            location.reload();
        }
    };
    $.fn.pad = function (str, padStr = "0", max) {
        str = str.toString();
        return str.length < max ? $.fn.pad(padStr + str, padStr, max) : str;
    }

    select2 = function() {
        let $select = $("select.display");
        let $selectTag = $("select.display-tag");
        $select.each(function () {
            let $this = $(this);
            $this.select2({
                tags: false,
                theme: $this.data('container-custom-class') ? "bootstrap4 " + $this.data('container-custom-class') : "bootstrap4",
                width: $this.data('width') ? $this.data('width') : $this.hasClass('w-100') ? '100%' : 'style',
                placeholder: $this.data('placeholder'),
                allowClear: Boolean($this.data('allow-clear')),
                containerCssClass: ':all:'
            });
        });

        $selectTag.each(function () {
            let $this = $(this);
            $this.select2({
                tags: true,
                maximumSelectionLength: $this.data('max-selections') ? $this.data('max-selections') : 0,
                theme: $this.data('container-custom-class') ? "bootstrap4 " + $this.data('container-custom-class') : "bootstrap4",
                width: $this.data('width') ? $this.data('width') : $this.hasClass('w-100') ? '100%' : 'style',
                placeholder: $this.data('placeholder'),
                allowClear: Boolean($this.data('allow-clear')),
            });
        });

        $select.filter('.ordered-selection').on("select2:select", function (evt) {
            let $element = $(evt.params.data.element);
            let $this = $(this);
            $element.detach();
            $this.append($element);
            $this.trigger("change");
        });
    };

    updateCheckbox = function (checkbox, newValue) {
        checkbox.prop('checked', !!newValue).val(newValue ? 1 : 0);
    };

    pageLoaderShow = function (message) {
        let loaderMessage = $("#loader-message");
        if (message) {
            loaderMessage.text(message);
        } else {
            loaderMessage.text(loaderMessage.data('default'));
        }

        $("#page-loader").show();
    };
    pageLoaderHide = function () {
        let message = $("#loader-message");
        message.text(message.data('default'));
        $("#page-loader").hide();
    };

    $(document).ready(function () {
        let companyChannelId = $('input#company-channel-id').val();
        let operatingPlanGroupId = $('input#operating-plan-group-id').val();
        if (companyChannelId) {
            companyChannel = Echo.channel(`company.${companyChannelId}`);
        }

        if (companyChannel) {
            if (operatingPlanGroupId) {
                companyChannel.listen('ProductionItemInProgress', (e) => {
                    // noinspection EqualityComparisonWithCoercionJS
                    if (operatingPlanGroupId == e.planGroupId && e.path !== window.location.href) {
                        $(location).attr('href', e.path);
                    }
                });
            }

            companyChannel.listen('ScanAlert', (e) => {
                // noinspection EqualityComparisonWithCoercionJS
                if (!operatingPlanGroupId || (e.planGroupId && operatingPlanGroupId == e.planGroupId)) {
                    let alert = $('#' + e.type);
                    if (e.deviceName) {
                        alert.find('#alert-for-device-name').text(e.deviceName);
                    }

                    alert.toast('show');
                }
            });
        }

        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function () {
            $('[data-toggle="popover"]').popover()
        })

        $('.toast').not('.hide').toast('show');

        $.extend( $.fn.dataTable.defaults, {
            searching: false,
            ordering:  false,
            info:  false,
            paging:  false,
            lengthChange:  false,
        } );

        let dtLangs = {
            fr: {
                processing: "Traitement en cours...",
                search: "Rechercher&nbsp;:",
                lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
                info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                infoEmpty: "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                infoFiltered: "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                infoPostFix: "",
                loadingRecords: "Chargement en cours...",
                zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
                paginate: {
                    first: "Premier",
                    previous: "Pr&eacute;c&eacute;dent",
                    next: "Suivant",
                    last: "Dernier"
                },
                aria: {
                    sortAscending: ": activer pour trier la colonne par ordre croissant",
                    sortDescending: ": activer pour trier la colonne par ordre décroissant"
                }
            }
        };

        let langCode = $("html").attr('lang');
        window.dtLocale = _.get(dtLangs, langCode, {});
        $('table.data-table').each(function () {
            let table = $(this);

            table.DataTable({
                paging: table.data('paging') ? table.data('paging') : false,
                ordering: table.data('ordering') ? table.data('ordering') : false,
                info: table.data('info') ? table.data('info') : false,
                searching: table.data('searching') ? table.data('searching') : false,
                lengthChange: table.data('length-change') ? table.data('length-change') : false,
                pageLength: table.data('page-length') ? table.data('page-length') : 10,
                rowReorder: table.data('row-reorder') ? {selector: 'tr'} : false,
                columnDefs: [
                    table.data('all-centered') ? {className: "text-center", targets: '_all'} : '',
                ],
                language: {
                    ...window.dtLocale,
                    ...{
                        emptyTable: table.data('empty-table')
                    }
                },
            })
        });

        select2();

        let checkBoxes = $("input[type='checkbox']");
        checkBoxes.each(function () {
            let $this = $(this);
            $this.prop('checked', $this.val() === '1');
        });
        checkBoxes.on("change", function () {
            let $this = $(this);
            $this.val($this.is(':checked') ? 1 : 0);
        });
    });

    // Add active state to sidbar nav links
    var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
        $("#layoutSidenav_nav .sb-sidenav a.nav-link").each(function() {
            if ($(this).attr('href') === path) {
                $(this).addClass("active");
            }
        });

    // Toggle the side navigation
    $("#sidebarToggle").on("click", function(e) {
        e.preventDefault();
        $("body").toggleClass("sb-sidenav-toggled").removeClass("sb-sidenav-hidden");
    });

    $('.window-resize').click(function () {
        let $this = $(this);
        let restorePlaceholder = $this.attr('data-restore-placeholder');
        let $maximized = $this.closest('#window-maximize-placeholder > div');
        if ($maximized.length !== 0) {
            $(restorePlaceholder).append($maximized);
            if (restorePlaceholder !== '#window-restore-placeholder') {
                $(restorePlaceholder).siblings().show();
            }
        } else {
            $('#window-maximize-placeholder').append($this.closest(restorePlaceholder + ' > div'));
            if (restorePlaceholder !== '#window-restore-placeholder') {
                $(restorePlaceholder).siblings().hide();
            }
        }
        $this.children('i').toggleClass('fa-window-maximize').toggleClass('fa-window-restore');
    });

    const calculator = {
        displayValue: '0',
        firstOperand: null,
        waitingForSecondOperand: false,
        operator: null,
    };

    function inputDigit(digit) {
        const {displayValue, waitingForSecondOperand} = calculator;

        if (waitingForSecondOperand === true) {
            calculator.displayValue = digit;
            calculator.waitingForSecondOperand = false;
        } else {
            calculator.displayValue = displayValue === '0' ? digit : displayValue + digit;
        }
    }

    function inputDecimal(dot) {
        // If the `displayValue` does not contain a decimal point
        if (!calculator.displayValue.includes(dot)) {
            // Append the decimal point
            calculator.displayValue += dot;
        }
    }

    function handleOperator(nextOperator) {
        const {firstOperand, displayValue, operator} = calculator
        const inputValue = parseFloat(displayValue);

        if (operator && calculator.waitingForSecondOperand) {
            calculator.operator = nextOperator;
            return;
        }

        if (firstOperand == null) {
            calculator.firstOperand = inputValue;
        } else if (operator) {
            const currentValue = firstOperand || 0;
            const result = performCalculation[operator](currentValue, inputValue);

            calculator.displayValue = String(result);
            calculator.firstOperand = result;
        }

        calculator.waitingForSecondOperand = true;
        calculator.operator = nextOperator;
    }

    const performCalculation = {
        '/': (firstOperand, secondOperand) => firstOperand / secondOperand,

        '*': (firstOperand, secondOperand) => firstOperand * secondOperand,

        '+': (firstOperand, secondOperand) => firstOperand + secondOperand,

        '-': (firstOperand, secondOperand) => firstOperand - secondOperand,

        '=': (firstOperand, secondOperand) => secondOperand
    };

    function resetCalculator() {
        calculator.displayValue = '0';
        calculator.firstOperand = null;
        calculator.waitingForSecondOperand = false;
        calculator.operator = null;
    }

    function updateDisplay() {
        const display = document.querySelector('.calculator-screen');
        display.value = calculator.displayValue;
    }

    updateDisplay();

    const keys = document.querySelector('.calculator-keys');
    keys.addEventListener('click', (event) => {
        const {target} = event;
        if (!target.matches('button')) {
            return;
        }

        if (target.classList.contains('operator')) {
            handleOperator(target.value);
            updateDisplay();
            return;
        }

        if (target.classList.contains('decimal')) {
            inputDecimal(target.value);
            updateDisplay();
            return;
        }

        if (target.classList.contains('all-clear')) {
            resetCalculator();
            updateDisplay();
            return;
        }

        inputDigit(target.value);
        updateDisplay();
    });

    let $widgetBatchCalculatorSelect = $('#widgetBatchCalculator');
    let showCalculatedBatch = function showCalculatedBatch() {
        let $select = $(this);
        $('#batch-calculator-widget-description > span').hide();
        let selected = $select.val();
        Cookies.set('widget-batch-calculator', selected);
        let selector = '#batch-calculator-widget-description > span[data-calculator-type="' + selected.replace(/\\/g, '-') + '"]';
        let $calculator = $(selector);
        $calculator.show();
    }
    $widgetBatchCalculatorSelect.on('change', showCalculatedBatch);

    let calculateBatch = function calculateBatch() {
        let posting = $.get({
            url: window.location.origin + '/companies/calculate-batch',
            data: {
                productionDate: $("#widgetProductionDateInput").val(),
                shelfLife: $("#widgetShelfLifeInput").val()
            }
        });

        posting.done(function (data) {
            $("#batch-calculator-widget-description").html(data);
            $('#widgetBatchCalculator').trigger('change');
        });

        posting.fail(function () {
            $("#batch-calculator-widget-description").html("ERR");
            $('#widgetBatchCalculator').trigger('change');
        });
    }

    let $widgetBatchCalculatorProductionDate = $("#widgetProductionDate");
    let $widgetShelfLifeInput = $("#widgetShelfLifeInput");
    $widgetBatchCalculatorProductionDate.datetimepicker(
        {
            date: moment(new Date(), 'YYYY-MM-DD').toDate(),
            format: 'YYYY-MM-DD',
            locale: 'en',
            icons: {
                time: "fas fa-clock",
                date: "fas fa-calendar",
                up: "fas fa-arrow-up",
                down: "fas fa-arrow-down"
            },
        }
    );
    $widgetBatchCalculatorProductionDate.on('change.datetimepicker', calculateBatch);
    $widgetShelfLifeInput.on('change', calculateBatch);
    $widgetShelfLifeInput.trigger('change');

    let calculatorChangeCallback = function () {
        let $select = $(this);
        $select.siblings('.batch-calculator-description').hide();
        let selected = $select.val();
        let selector = '.batch-calculator-description[data-calculator-type="' + selected.replace(/\\/g, '-') + '"]';
        let $calculator = $select.siblings(selector);
        $calculator.show();
    };
    let $calculatorSelect = $('select.batch-calculator-select');
    $calculatorSelect.on('change', calculatorChangeCallback);
    $calculatorSelect.trigger('change');

    const $hideTopNavButton = $('#hide-sb-nav');
    const $showTopNavButton = $('#show-sb-nav');
    $hideTopNavButton.click(function () {
        $('body').addClass('sb-nav-hidden');
        $hideTopNavButton.hide();
        $showTopNavButton.show();
    });
    $showTopNavButton.click(function () {
        $('body').removeClass('sb-nav-hidden');
        $hideTopNavButton.show();
        $showTopNavButton.hide();
    });

})(jQuery);
